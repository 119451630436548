var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "accordion",
        _vm.classNames,
        _vm.localIsVisible ? "is-open" : "",
        _vm.isCollapsible ? "" : "is-not-collapsible",
      ],
    },
    [
      _c(
        "div",
        { staticClass: "accordion-header", on: { click: _vm.toggle } },
        [_vm._t("header")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "content", staticClass: "accordion-content" },
        [_vm._t("content")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }