<script>
import VTour from 'vue-tour/src/components/VTour';
import { DEFAULT_OPTIONS } from 'vue-tour/src/shared/constants';

export default {
    extends: VTour,

    name: 'Tour',

    props: {
        name: {
            type: String,
            default: 'tour'
        },

        options: {
            type: Object,
            default: () => {
                return {
                    ...DEFAULT_OPTIONS,
                    labels: {
                        buttonSkip: 'Tour beenden',
                        buttonPrevious: 'zurück',
                        buttonNext: 'weiter',
                        buttonStop: 'Tour beenden'
                    }
                }
            }
        }
    }
}

</script>
