var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("aside", { staticClass: "is-faqs" }, [
    _c("div", {
      staticClass: "faqs-overlay",
      on: { click: _vm.hideUserSettings },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "faqs-wrapper" }, [
      _c("h2", [_vm._v("Einstellungen")]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("label", { staticClass: "label" }, [_vm._v("MAILBOX")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "control" },
          [
            _c("email-select", {
              attrs: { save: false },
              model: {
                value: _vm.emailSelected,
                callback: function ($$v) {
                  _vm.emailSelected = $$v
                },
                expression: "emailSelected",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("label", { staticClass: "label" }, [_vm._v("SCHECKBUCH")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "control" },
          [
            _c("check-book-select", {
              model: {
                value: _vm.checkBook,
                callback: function ($$v) {
                  _vm.checkBook = $$v
                },
                expression: "checkBook",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field" }, [
        _c("label", { staticClass: "label" }, [_vm._v("AKTIVE NUTZER")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "control" },
          [
            _c(
              "toggle-switch",
              {
                model: {
                  value: _vm.allUsers,
                  callback: function ($$v) {
                    _vm.allUsers = $$v
                  },
                  expression: "allUsers",
                },
              },
              [
                _c("option", { domProps: { value: false } }, [
                  _vm._v("nur aktive User anzeigen"),
                ]),
                _vm._v(" "),
                _c("option", { domProps: { value: true } }, [
                  _vm._v("alle User anzeigen"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "field is-grouped buttons is-grouped-right" }, [
        !_vm.isLoading
          ? _c(
              "button",
              {
                staticClass: "button is-large is-cancel",
                on: { click: _vm.hideUserSettings },
              },
              [_vm._v("Abbrechen")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "button is-primary is-large",
            class: { "is-loading": _vm.isLoading },
            attrs: { disabled: !_vm.isDisabled },
            on: { click: _vm.save },
          },
          [_vm._v("Speichern")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }