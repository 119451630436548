<template>
    <div id="app" :class="{'is-menu-opened': isMenuOpened, 'locked': isLocked, 'is-right-aside-hidden': isRightAsideHidden}">
        <app-header />
        <app-nav />

        <keep-alive>
            <faqs v-if="isFaqOpen" />
            <user-settings v-if="isUserSettingsOpen"/>
        </keep-alive>

        <div class="wrapper">
            <slot />
        </div>
    </div>
</template>

<script>
import Faqs from '@components/Faqs'
import UserSettings from '@components/UserSettings'
import AppHeader from './Header'
import AppNav from './Nav'

export default {
    name: 'layout',
    components: {
        Faqs,
        UserSettings,
        AppHeader,
        AppNav
    },

    computed: {
        isFaqOpen() {
            return this.$layout.faq
        },

        isUserSettingsOpen() {
            return this.$layout.userSettings
        },

        isMenuOpened() {
            return this.$layout.menu
        },

        isLocked() {
            return this.$layout.locked
        },

        isRightAsideHidden() {
            return this.$layout.hiddenRightAside
        }
    }
}
</script>
