var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", [
    _c(
      "a",
      { staticClass: "logo light-version", attrs: { href: "/user-dashboard" } },
      [
        _c("img", {
          attrs: {
            src: "/clientSpecific/logo/logo_transparent.png",
            alt: "Logo",
          },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "header-link hide-menu", on: { click: _vm.toggleMenu } },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("more_vert")])]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn-show-left-side-on-small",
        class: { "is-visible": _vm.infos.showButton },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.showInfos.apply(null, arguments)
          },
        },
      },
      [
        _c("i", { staticClass: "material-icons" }, [
          _vm._v("keyboard_backspace"),
        ]),
        _vm._v(" Infos\n    "),
      ]
    ),
    _vm._v(" "),
    _vm.pageName
      ? _c("div", { staticClass: "page-name" }, [_vm._v(_vm._s(_vm.pageName))])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "nav",
      { staticClass: "inside-page-navigation", attrs: { role: "navigation" } },
      [
        _vm.orderID
          ? [
              _c("a", _vm._b({}, "a", _vm.orderLink(), false), [
                _vm._v("Reisemaske"),
              ]),
              _vm._v(" "),
              _c("a", _vm._b({}, "a", _vm.orderLink("calculations"), false), [
                _vm._v("Kalkulation"),
              ]),
              _vm._v(" "),
              _c(
                "a",
                _vm._b(
                  { staticClass: "is-hidden-on-small" },
                  "a",
                  _vm.orderLink("offer"),
                  false
                ),
                [_vm._v("Angebot")]
              ),
              _vm._v(" "),
              _c(
                "a",
                _vm._b(
                  { staticClass: "is-hidden-on-small" },
                  "a",
                  _vm.orderLink("catalog"),
                  false
                ),
                [_vm._v("Katalogtext")]
              ),
              _vm._v(" "),
              _c(
                "a",
                _vm._b(
                  { staticClass: "is-hidden-on-small" },
                  "a",
                  _vm.orderLink("roomlist"),
                  false
                ),
                [_vm._v("Zimmerliste")]
              ),
              _vm._v(" "),
              _c(
                "a",
                _vm._b(
                  { staticClass: "is-hidden-on-small" },
                  "a",
                  _vm.orderLink("trip-details"),
                  false
                ),
                [_vm._v("Reiseunterlagen")]
              ),
              _vm._v(" "),
              _c(
                "a",
                _vm._b(
                  { staticClass: "is-hidden-on-small" },
                  "a",
                  _vm.orderLink("voucher"),
                  false
                ),
                [_vm._v("Voucher")]
              ),
              _vm._v(" "),
              _c(
                "a",
                _vm._b(
                  { staticClass: "is-hidden-on-small" },
                  "a",
                  _vm.orderLink("client-invoice"),
                  false
                ),
                [_vm._v("Kundenrechnungen")]
              ),
              _vm._v(" "),
              _c(
                "a",
                _vm._b(
                  { staticClass: "is-hidden-on-small" },
                  "a",
                  _vm.orderLink("invoices"),
                  false
                ),
                [_vm._v("Zahlungen an LT")]
              ),
              _vm._v(" "),
              _c(
                "a",
                _vm._b(
                  { staticClass: "is-hidden-on-small" },
                  "a",
                  _vm.orderLink("documents"),
                  false
                ),
                [_vm._v("Dokumente")]
              ),
              _vm._v(" "),
              _c(
                "a",
                _vm._b(
                  { staticClass: "is-hidden-on-small" },
                  "a",
                  _vm.orderLink("emails"),
                  false
                ),
                [_vm._v("Emails")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-menu-on-small",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleOrderMenu.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v("Mehr "),
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("keyboard_arrow_down"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "menu-on-small",
                  class: {
                    "is-active": _vm.orderMenu.isActive,
                    "is-visible": _vm.orderMenu.isVisible,
                  },
                },
                [
                  _c("a", _vm._b({}, "a", _vm.orderLink("offer"), false), [
                    _vm._v("Angebot"),
                  ]),
                  _vm._v(" "),
                  _c("a", _vm._b({}, "a", _vm.orderLink("catalog"), false), [
                    _vm._v("Katalogtext"),
                  ]),
                  _vm._v(" "),
                  _c("a", _vm._b({}, "a", _vm.orderLink("roomlist"), false), [
                    _vm._v("Zimmerliste"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    _vm._b({}, "a", _vm.orderLink("trip-details"), false),
                    [_vm._v("Reiseunterlagen")]
                  ),
                  _vm._v(" "),
                  _c("a", _vm._b({}, "a", _vm.orderLink("voucher"), false), [
                    _vm._v("Voucher"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    _vm._b({}, "a", _vm.orderLink("client-invoice"), false),
                    [_vm._v("Kundenrechnungen")]
                  ),
                  _vm._v(" "),
                  _c("a", _vm._b({}, "a", _vm.orderLink("invoices"), false), [
                    _vm._v("Zahlungen an LT"),
                  ]),
                  _vm._v(" "),
                  _c("a", _vm._b({}, "a", _vm.orderLink("documents"), false), [
                    _vm._v("Dokumente"),
                  ]),
                  _vm._v(" "),
                  _c("a", _vm._b({}, "a", _vm.orderLink("emails"), false), [
                    _vm._v("Emails"),
                  ]),
                ]
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c("input", {
      staticClass: "nav-search",
      attrs: { type: "text", placeholder: "Schnellsuche", role: "search" },
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn-show-right-side-on-small",
        class: { "is-visible": _vm.orderID },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.showDashboard.apply(null, arguments)
          },
        },
      },
      [
        _c("i", { staticClass: "material-icons" }, [
          _vm._v("keyboard_backspace"),
        ]),
        _vm._v(" Infos\n    "),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "simple-menu btn-help is-pointer",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggleFaq.apply(null, arguments)
          },
        },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("live_help")])]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "simple-menu btn-help is-pointer",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.toggleUserSettings.apply(null, arguments)
          },
        },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("settings")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }