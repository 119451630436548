var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("multiselect", {
    attrs: {
      options: _vm.organisations,
      multiple: _vm.multiple,
      "allow-empty": false,
      "internal-search": false,
      "track-by": "id",
      label: "name",
      placeholder: "Organisationen wählen",
      loading: _vm.isLoading,
    },
    model: {
      value: _vm.organisationSelected,
      callback: function ($$v) {
        _vm.organisationSelected = $$v
      },
      expression: "organisationSelected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }