var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("async-select", {
    attrs: { api: "check_books", placeholder: "Scheckbuch auswählen" },
    model: {
      value: _vm.checkbook,
      callback: function ($$v) {
        _vm.checkbook = $$v
      },
      expression: "checkbook",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }