<template>
    <aside class="is-faqs">
        <div class="faqs-overlay" @click="hideFaq"></div>
        <div class="faqs-wrapper">
            <div class="filters">
                <div class="level is-full-width">
                    <button class="button is-primary" v-on:click.prevent="startTour">
                        <i class="fa fa-cogs"></i>Hilfe zur Funktionsweise
                    </button>

                    <a href="/faqs" class="is-turqoise-text link">alle anzeigen</a>
                </div>
            </div>
            <div class="filters">
                <input
                    v-model     = "searchQuery"
                    type        = "text"
                    class       = "input"
                    placeholder = "Schnellsuche"
                    v-on:input  = "searchFaqs"
                />
            </div>

            <loading v-if="isLoading" />

            <template v-else>
                <accordion
                    v-for      = "category in categories"
                    v-bind:key = "category.key"
                >
                    <template slot="header">
                        <span>{{ category.value }}</span>
                    </template>

                    <div class="redesign-table" slot="content">
                        <div
                            v-for="item in category.questions"
                            v-bind:key="item.id"
                            class="row"
                            v-bind:class="{'is-active' : selectedQuestion && item.id === selectedQuestion.id}"
                            v-on:click.prevent="selectQuestion(item)"
                        >
                            <div class="cell is-truncated">{{ item.question }}</div>
                        </div>
                    </div>
                </accordion>

                <div v-if="selectedQuestion">
                    <h2>VORSCHAU</h2>
                    <div class="bp-panel">
                        <div class="panel-content" v-html="selectedQuestion.answer"></div>
                    </div>
                </div>
            </template>
        </div>
    </aside>
</template>

<script>
import debounce from 'lodash/debounce';
import Loading from '@components/Loading';
import Accordion from '@components/Accordion';
import { getFaqs, optionsFaqs } from '@api';

export default {
    data: function() {
        return {
            searchQuery: '',
            isLoading: false,
            faqs: [],
            selectedQuestion: null,
            options: {
                category: []
            }
        }
    },

    created() {
        this.fetchFaqs()
        this.fetchOptions()
    },

    computed: {
        categories: function() {
            const categories = this.faqs.map(faq => faq.category)
            const uniqCategories = [...new Set(categories)]

            return this.options.category
                .filter(category => uniqCategories.includes(category.key))
                .map(category => ({
                    ...category,
                    questions: this.faqs.filter(faq => faq.category === category.key)
                }))
        }
    },

    methods: {
        fetchFaqs: function() {
            this.isLoading = true;

            getFaqs({
                category: 'faq_',
                _search: this.searchQuery,
                _itemsPerPage: 10
            }).then(response => {
                this.faqs = response.data;
                if (response.data.length > 0) {
                    this.selectQuestion(response.data[0])
                } else {
                    this.selectedQuestion = null
                }
            }, error => console.error(error))
            .then(() => {
                this.isLoading = false;
            })
        },

        searchFaqs: debounce(function() {
            this.fetchFaqs()
        }, 1000),

        fetchOptions: function() {
            optionsFaqs().then(response => {
                this.options = response.data;
            })
        },

        selectQuestion: function(question) {
            this.selectedQuestion = JSON.parse(JSON.stringify(question))
        },

        hideFaq: function() {
            this.$layout.toggleFaq(false)
        },

        startTour() {
            if (this.$tours.tour) {
                this.$tours.tour.start()
                this.hideFaq()
            } else if (this.$tours && Object.keys(this.$tours).length > 0) {
                const tourName = Object.keys(this.$tours)[0]
                this.$tours[tourName].start()
                this.hideFaq()
            }
        }
    },

    components: {
        Loading,
        Accordion
    }
}
</script>
