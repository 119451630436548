var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "buttons has-addons toggle-switch", class: _vm.divClass },
    [
      _vm._l(_vm.options, function (option) {
        return _c(
          "button",
          {
            key: option.value,
            staticClass: "button",
            class: [{ "is-primary": _vm.isActive(option) }, _vm.btnClass],
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.handleClick(option)
              },
            },
          },
          [_c("vnodes", { attrs: { vnodes: option.children } })],
          1
        )
      }),
      _vm._v(" "),
      false ? _c("div", [_vm._t("default")], 2) : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }