<template>
    <multiselect
        v-model                 = "emailSelected"
        v-bind:options          = "emails"
        v-bind:internal-search  = "false"
        track-by                = "id"
        :label                   = "label"
        placeholder             = "Email wählen"
        :allow-empty="false"
    />
</template>



<script>

import Multiselect from './Multiselect';
import {currentUser} from "@utilities/functions";
import {editUser} from "@api";
import {notifyError} from "@components/Notification";


export default {

    props: {
        value:       {},
        save: {default: true},
        label: {
            type: String,
            default: 'email'
        }
    },

    computed: {
        emailSelected: {
            get: function () {
                return this.value;
            },
            set: function (email) {
                if(this.save){
                    this.updateCurrentOrganisation(email);
                } else {
                    this.$emit('input', email);
                }
            }
        },
    },

    data: function () {
        return {
            emails: this.getEmails(),
        }
    },

    methods: {
        updateCurrentOrganisation: function (email) {
            editUser({
                id: currentUser('id'),
                data: {
                    currentOrganisation: email.organisation,
                },
            }).then(response => {
                //notifySuccess('Die Einstellungen wurden aktualisiert!');
                const user = JSON.parse(localStorage.getItem('user'));
                user.user.currentOrganisation = response.data.currentOrganisation;
                localStorage.setItem('user', JSON.stringify(user));
                this.$emit('input', email);
                this.$emit('setOrganisation', response.data.currentOrganisation);
            }, error => {
                notifyError("Die Einstellungen konnten nicht aktualisiert werden!")
            }).then(() => this.isLoading = false )
        },
        getEmails() {
            const emails = currentUser('userEmails')
            if(emails === []){
                return []
            }
            return emails.map((el) => {
                el['fullName'] = el.emailFrom.split('|')[0]
                return el
            })
        }
    },

    components: {
        Multiselect
    }
}
</script>
