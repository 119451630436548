var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        name: _vm.transition,
        mode: "in-out",
        appear: "",
        "appear-active-class": _vm.enterClass,
        "enter-active-class": _vm.enterClass,
        "leave-active-class": _vm.leaveClass,
      },
      on: { "after-leave": _vm.afterLeave },
    },
    [
      _vm.show
        ? _c(
            "div",
            {
              class: [
                "notification",
                "animated",
                _vm.type ? `is-${_vm.type}` : "",
              ],
            },
            [
              _c("button", {
                staticClass: "delete touchable",
                on: {
                  click: function ($event) {
                    return _vm.closedByUser()
                  },
                },
              }),
              _vm._v(" "),
              _vm.title
                ? _c("div", { staticClass: "title is-5" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ])
                : _vm._e(),
              _vm._v("\n    " + _vm._s(_vm.message) + "\n  "),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }