var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("multiselect", {
    attrs: {
      options: _vm.emails,
      "internal-search": false,
      "track-by": "id",
      label: _vm.label,
      placeholder: "Email wählen",
      "allow-empty": false,
    },
    model: {
      value: _vm.emailSelected,
      callback: function ($$v) {
        _vm.emailSelected = $$v
      },
      expression: "emailSelected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }