<template>
    <div class="buttons has-addons toggle-switch" :class="divClass">
        <button
            v-for="option in options"
            v-bind:key="option.value"
            v-on:click.prevent.stop="handleClick(option)"
            class="button"
            :class="[{'is-primary': isActive(option)}, btnClass]"
        ><vnodes :vnodes="option.children" /></button>

        <div v-if="false"><slot /></div>
    </div>
</template>

<script>
import has from 'lodash/has';
export default {
    props: {
        value: {},
        btnClass: {
            type: String,
            default: ''
        },
        divClass: {
            type: String,
            default: ''
        }
    },

    computed: {
        options: function () {
            return  this.$slots.default
                .filter(el => el.tag === 'option')
                .map(el => {
                    return {
                        children: el.children,
                        value: has(el.data, 'attrs') ? el.data.attrs.value : el.data.domProps.value,
                    }
                })
        },
    },

    methods: {
        handleClick: function (option) {
            if (Array.isArray(this.value)) {
                if (this.isActive(option)) {
                    this.$emit('input', [...this.value].filter(o => o !== option.value))
                } else {
                    this.$emit('input', [...this.value, option.value])
                }
            } else {
                if (this.isActive(option)) {
                    this.$emit('input', this.options.filter(o => o.value !== option.value)[0].value)
                } else {
                    this.$emit('input', option.value)
                }
            }

        },

        isActive: function (option) {
            if (Array.isArray(this.value)) {
                return this.value
                    .includes(option.value);
            } else {
                return JSON.stringify(this.value) === JSON.stringify(option.value);
            }
        }
    },

    components: {
        Vnodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes
        }
    }
}
</script>

