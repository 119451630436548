import Vue from 'vue'
import Tooltip from 'v-tooltip';
import Vuex from 'vuex';
import VueTour from 'vue-tour';
import axios from 'axios';
import bugsnagVue from '@bugsnag/plugin-vue';
import VueFlags from "@growthbunker/vueflags";
import '../scss/style';

import { authHeader } from '@utilities/functions';
import BugsnagClient from '@utilities/bugsnag';
import Layout from '@root/layout';
import Tour from '@components/Tour';

global.Vue = Vue;
Vue.use(Layout);
Vue.use(Tooltip);
Vue.use(Vuex);
Vue.use(VueTour);
Vue.use(VueFlags, {iconPath: '/assets/img/flags/',})


Vue.component('tour', Tour);


// AUTH
axios.defaults.headers.common = { ...axios.defaults.headers.common, ...authHeader() };
if(__ENV__ === 'development'){
    //axios.defaults.baseURL = 'https://test-travelize.buspaket.de'
}
