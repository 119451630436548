<template>
    <header>
        <a href="/user-dashboard" class="logo light-version">
            <img :src="'/clientSpecific/logo/logo_transparent.png'" alt="Logo" />
        </a>


        <div class="header-link hide-menu" @click="toggleMenu"><i class="material-icons">more_vert</i></div>

        <button
            class="btn-show-left-side-on-small"
            :class="{'is-visible': infos.showButton}"
            @click.prevent="showInfos"
        >
            <i class="material-icons">keyboard_backspace</i> Infos
        </button>

        <div class="page-name" v-if="pageName">{{ pageName }}</div>

        <nav role="navigation" class="inside-page-navigation">
            <template v-if="orderID">
                <a v-bind="orderLink()"                 >Reisemaske</a>
                <a v-bind="orderLink('calculations')"   >Kalkulation</a>
                <a v-bind="orderLink('offer')"          class="is-hidden-on-small">Angebot</a>
                <a v-bind="orderLink('catalog')"        class="is-hidden-on-small">Katalogtext</a>
                <a v-bind="orderLink('roomlist')"       class="is-hidden-on-small">Zimmerliste</a>
                <a v-bind="orderLink('trip-details')"   class="is-hidden-on-small">Reiseunterlagen</a>
                <a v-bind="orderLink('voucher')"        class="is-hidden-on-small">Voucher</a>
                <a v-bind="orderLink('client-invoice')" class="is-hidden-on-small">Kundenrechnungen</a>
                <a v-bind="orderLink('invoices')"       class="is-hidden-on-small">Zahlungen an LT</a>
                <a v-bind="orderLink('documents')"      class="is-hidden-on-small">Dokumente</a>
                <a v-bind="orderLink('emails')"         class="is-hidden-on-small">Emails</a>

                <button class="btn-menu-on-small" @click.prevent="toggleOrderMenu">Mehr <i class="material-icons">keyboard_arrow_down</i></button>
                <div class="menu-on-small" :class="{'is-active': orderMenu.isActive, 'is-visible': orderMenu.isVisible}">
                    <a v-bind="orderLink('offer')"         >Angebot</a>
                    <a v-bind="orderLink('catalog')"       >Katalogtext</a>
                    <a v-bind="orderLink('roomlist')"      >Zimmerliste</a>
                    <a v-bind="orderLink('trip-details')"  >Reiseunterlagen</a>
                    <a v-bind="orderLink('voucher')"       >Voucher</a>
                    <a v-bind="orderLink('client-invoice')">Kundenrechnungen</a>
                    <a v-bind="orderLink('invoices')"      >Zahlungen an LT</a>
                    <a v-bind="orderLink('documents')"     >Dokumente</a>
                    <a v-bind="orderLink('emails')"        >Emails</a>
                </div>
            </template>
        </nav>

        <input type="text" placeholder="Schnellsuche" class="nav-search" role="search">

        <button
            class="btn-show-right-side-on-small"
            :class="{'is-visible': orderID}"
            @click.prevent="showDashboard"
        >
            <i class="material-icons">keyboard_backspace</i> Infos
        </button>

        <div class="simple-menu btn-help is-pointer" v-on:click.prevent="toggleFaq">
            <i class="material-icons">live_help</i>
        </div>

        <div class="simple-menu btn-help is-pointer" v-on:click.prevent="toggleUserSettings">
            <i class="material-icons">settings</i>
        </div>
    </header>
</template>

<script>
import SimpleMenu from '@components/SimpleMenu'

export default {
    data() {
        return {
            orderMenu: {
                isActive: false,
                isVisible: false
            }
        }
    },

    computed: {
        pageName() {
            return this.$layout.pageName
        },

        infos() {
            return this.$layout.infos
        },

        orderID() {
            const pathName = window.location.pathname;
            if (pathName.startsWith('/orders/') && pathName !== '/orders/add') {
                return parseInt(pathName.split('/')[2], 10);
            }
            return false;
        },
    },


    methods: {
        showInfos() {
            this.$layout.showInfos()
        },

        showDashboard() {
            this.$layout.showDashboard()
        },

        orderLink(subpage = '') {
            const href = `/orders/${this.orderID}${subpage !== '' ? `/${subpage}` : ''}`
            return {
                href,
                class: {
                    'is-active': href === window.location.pathname
                }
            }
        },

        toggleOrderMenu() {
            if (this.orderMenu.isActive) {
                this.orderMenu.isVisible = false;
                setTimeout(() => { this.orderMenu.isActive = false }, 200);
            } else {
                this.orderMenu.isActive = true;
                setTimeout(() => { this.orderMenu.isVisible = true }, 200);
            }
        },

        toggleMenu() {
            this.$layout.toggleMenu()
        },

        toggleFaq() {
            this.$layout.toggleFaq()
        },

        toggleUserSettings() {
            this.$layout.toggleUserSettings()
        }
    },

    components: {
        SimpleMenu
    }
}
</script>
