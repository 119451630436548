var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "loading-data",
        _vm.classNames,
        _vm.isVertical ? "is-vertical" : "",
      ],
    },
    [
      _vm._v("\n\n    " + _vm._s(_vm.textInFront) + "\n\n    "),
      _vm._m(0),
      _vm._v("\n    " + _vm._s(_vm.textAfter) + "\n"),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spinner" }, [
      _c("div", { staticClass: "rect1" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect2" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect3" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect4" }),
      _vm._v(" "),
      _c("div", { staticClass: "rect5" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }