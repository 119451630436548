<template>
    <async-select
        v-model="checkbook"
        api     = "check_books"
        placeholder="Scheckbuch auswählen"
    />

</template>



<script>

import Multiselect from './Multiselect';
import {currentUser} from "@utilities/functions";
import {editUser} from "@api";
import {notifyError} from "@components/Notification";
import AsyncSelect from "@components/form/AsyncSelect";


export default {
    components: {AsyncSelect},
    props: {
        value:       {},
    },

    computed: {
        checkbook: {
            get: function () {
                return this.value;
            },
            set: function (checkBook) {
                this.updateCurrentOrganisation(checkBook);
            }
        },
    },

    methods: {
        updateCurrentOrganisation: function (checkBook) {
             const user = JSON.parse(localStorage.getItem('user'));
                user.checkBook = checkBook;
                localStorage.setItem('user', JSON.stringify(user));
                this.$emit('input', checkBook);
        }
    },
}
</script>
