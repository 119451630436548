<template>
    <aside class="is-faqs">
        <div class="faqs-overlay" @click="hideUserSettings"></div>
        <div class="faqs-wrapper">


            <h2>Einstellungen</h2>


<!--            <div class="field">
                <label class="label">REISEN ANZEIGEN VON</label>
                <div class="control">
                    <organisation-select v-model="organisations"/>
                </div>
            </div>-->
            <div class="field">
                <label class="label">MAILBOX</label>
                <div class="control">
                    <email-select v-model="emailSelected" :save="false"></email-select>
                </div>
            </div>

            <div class="field">
                <label class="label">SCHECKBUCH</label>
                <div class="control">
                    <check-book-select v-model="checkBook"></check-book-select>
                </div>
            </div>

            <div class="field">
                <label class="label">AKTIVE NUTZER</label>
                <div class="control">
                    <toggle-switch v-model="allUsers">
                        <option :value="false">nur aktive User anzeigen</option>
                        <option :value="true">alle User anzeigen</option>
                    </toggle-switch>
                </div>
            </div>
            <div class="field is-grouped buttons is-grouped-right">
                <button v-if="!isLoading" class="button is-large is-cancel" v-on:click="hideUserSettings">Abbrechen</button>
                <button class="button is-primary is-large" v-bind:disabled="!isDisabled" v-bind:class="{ 'is-loading' : isLoading }" v-on:click="save">Speichern</button>
            </div>
        </div>

    </aside>
</template>

<script>

import ToggleSwitch from '@components/form/ToggleSwitch';
import Accordion from '@components/Accordion';
import  Multiselect from '@components/form/Multiselect';
import  OrganisationSelect from '@components/form/OrganisationSelect';
import {editUser} from '@api';
import { currentUser } from '@utilities/functions';
import {notifyError, notifySuccess} from "@components/Notification";
import EmailSelect from "@components/form/EmailSelect";
import CheckBookSelect from "@components/form/CheckBookSelect";



export default {
    data: function() {
        return {
            organisations: currentUser('organisations') ? currentUser('organisations') : [],
            currentOrganisation: currentUser('currentOrganisation'),
            isLoading: false,
            checkBook: localStorage.getItem('user') && localStorage.getItem('user').checkBook ? localStorage.getItem('user').checkBook : null,
            allUsers: null,
        }
    },

    computed: {
        isDisabled: function() {
            return this.currentOrganisation;
/*
            return this.currentOrganisation && this.organisations && this.organisations.length > 0;
*/
        },
        emailSelected: {
            get: function() {
                return currentUser('userEmails').find(email => email.organisation === '/api/organisations/' + this.currentOrganisation.id);
            },
            set: function(email) {
                this.currentOrganisation = currentUser('organisations').find(orga => '/api/organisations/' + orga.id === email.organisation);
            }
        },

    },

    methods: {
        hideUserSettings: function() {
            this.$layout.toggleUserSettings(false)
        },
        save: function () {
            this.isLoading = true;

            editUser({
                id: currentUser('id'),
                data: {
                    organisations: this.organisations.map(orga => { return '/api/organisations/' + orga.id}),
                    currentOrganisation: '/api/organisations/' + this.currentOrganisation.id,
                    settings : {
                        showAllUsers: this.allUsers,
                        fillBCC: currentUser('settings') ? currentUser('settings')['fillBCC'] : null
                    }
                },
            }).then(response => {
                notifySuccess('Die Einstellungen wurden aktualisiert!');
                const user = JSON.parse(localStorage.getItem('user'));
                user.user.organisations = this.organisations;
                user.user.currentOrganisation = this.currentOrganisation;
                if(!user.user.settings) {
                    user.user.settings = [];
                }
                user.user.settings['showAllUsers'] = this.allUsers;
                localStorage.setItem('user', JSON.stringify(user));
                this.hideUserSettings();
            }, error => {
                notifyError("Die Einstellungen konnten nicht aktualisiert werden!")
            }).then(() => this.isLoading = false )

        }
    },

    mounted () {
        this.allUsers = currentUser('settings') ? currentUser('settings')['showAllUsers'] : false;
    },

    components: {
        CheckBookSelect,
        EmailSelect,
        Accordion,
        OrganisationSelect,
        Multiselect,
        ToggleSwitch
    }
}
</script>
