var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    {
      ref: "select",
      attrs: {
        "track-by": "id",
        "custom-label": _vm.customLabel,
        options: _vm.options,
        loading: _vm.isLoading,
        multiple: _vm.multiple,
        "allow-empty": _vm.allowEmpty,
        "internal-search": false,
        placeholder: _vm._placeholder,
        disabled: _vm.disabled,
      },
      on: {
        "search-change": _vm.onSearch,
        select: _vm.onSelect,
        close: function ($event) {
          return _vm.$emit("close", _vm.tempString)
        },
      },
      scopedSlots: _vm._u(
        [
          {
            key: "selection",
            fn: function ({ values, search, isOpen, removeElement }) {
              return [
                _vm._t("selection", null, null, {
                  values,
                  search,
                  isOpen,
                  removeElement,
                }),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.innerValue,
        callback: function ($$v) {
          _vm.innerValue = $$v
        },
        expression: "innerValue",
      },
    },
    [
      !!_vm.innerValue && _vm.clearable && !_vm.multiple
        ? _c("div", {
            staticClass: "multiselect__clear",
            attrs: { slot: "clear" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                _vm.innerValue = _vm.multiple ? [] : null
              },
            },
            slot: "clear",
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.creatable
        ? _c("span", { attrs: { slot: "caret" }, slot: "caret" }, [
            _c(
              "a",
              {
                staticClass: "multiselect-crud-link",
                attrs: { href: `/${_vm._api}?add` },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.$emit("create")
                  },
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("add")])]
            ),
          ])
        : _vm.options.length === 0
        ? _c("span", { attrs: { slot: "caret" }, slot: "caret" }, [
            _vm.crudLinkTitle !== "" && _vm.isEmptyResults
              ? _c(
                  "a",
                  {
                    staticClass: "multiselect-crud-link",
                    attrs: {
                      href: "/" + _vm._api + "?add",
                      title: _vm.crudLinkTitle,
                      target: "_blank",
                    },
                  },
                  [_c("i", { staticClass: "material-icons" }, [_vm._v("add")])]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }