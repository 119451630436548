<template>
    <div v-bind:class="[
        'loading-data',
        classNames,
        isVertical ? 'is-vertical' : ''
    ]">

        {{ textInFront }}

        <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
        </div>
        {{ textAfter }}
    </div>
</template>

<script>
export default {
    props: {
        classNames:  { type: String, default: '' },
        textInFront: { type: String, default: 'Daten werden geladen...' },
        textAfter:   { type: String, default: 'Bitte warten' },
        isVertical:  { type: Boolean, default: false }
    }
}
</script>
