var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("aside", { staticClass: "is-faqs" }, [
    _c("div", { staticClass: "faqs-overlay", on: { click: _vm.hideFaq } }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "faqs-wrapper" },
      [
        _c("div", { staticClass: "filters" }, [
          _c("div", { staticClass: "level is-full-width" }, [
            _c(
              "button",
              {
                staticClass: "button is-primary",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.startTour.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "fa fa-cogs" }),
                _vm._v("Hilfe zur Funktionsweise\n                "),
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "is-turqoise-text link",
                attrs: { href: "/faqs" },
              },
              [_vm._v("alle anzeigen")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "filters" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchQuery,
                expression: "searchQuery",
              },
            ],
            staticClass: "input",
            attrs: { type: "text", placeholder: "Schnellsuche" },
            domProps: { value: _vm.searchQuery },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.searchQuery = $event.target.value
                },
                _vm.searchFaqs,
              ],
            },
          }),
        ]),
        _vm._v(" "),
        _vm.isLoading
          ? _c("loading")
          : [
              _vm._l(_vm.categories, function (category) {
                return _c(
                  "accordion",
                  { key: category.key },
                  [
                    _c("template", { slot: "header" }, [
                      _c("span", [_vm._v(_vm._s(category.value))]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "redesign-table",
                        attrs: { slot: "content" },
                        slot: "content",
                      },
                      _vm._l(category.questions, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "row",
                            class: {
                              "is-active":
                                _vm.selectedQuestion &&
                                item.id === _vm.selectedQuestion.id,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.selectQuestion(item)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "cell is-truncated" }, [
                              _vm._v(_vm._s(item.question)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  2
                )
              }),
              _vm._v(" "),
              _vm.selectedQuestion
                ? _c("div", [
                    _c("h2", [_vm._v("VORSCHAU")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "bp-panel" }, [
                      _c("div", {
                        staticClass: "panel-content",
                        domProps: {
                          innerHTML: _vm._s(_vm.selectedQuestion.answer),
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ],
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }