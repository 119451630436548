import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from "@bugsnag/plugin-vue";

const bugsnagClient = Bugsnag.start(
    {
        apiKey: '3249099c47d4edb9b20dc4b478620339',
        plugins: [new BugsnagPluginVue()],
        releaseStage: __ENV__,
        notifyReleaseStages: [ 'production' ]
    }
)

export default bugsnagClient;
