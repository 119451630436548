import Layout from './Layout';
const LayoutPlugin = {
    install(Vue) {
        Vue.component(Layout.name, Layout);
        Vue.prototype.$layout = {
            vm: new Vue({
                data: function () {
                    return {
                        pageName: '',
                        locked: false,
                        menu: false,
                        faq: false,
                        userSettings: false,
                        infos: {
                            isActive: false,
                            isVisible: false,
                        },
                        dashboard: {
                            isActive: false,
                            isVisible: false
                        },
                        hiddenRightAside: false
                    }
                }
            }),

            get pageName() {
                return this.vm.$data.pageName;
            },

            get infos() {
                return this.vm.$data.infos;
            },

            get menu() {
                return this.vm.$data.menu;
            },

            get userSettings() {
                return this.vm.$data.userSettings;
            },

            get dashboard() {
                return this.vm.$data.dashboard;
            },

            get locked() {
                return this.vm.$data.locked;
            },

            get faq() {
                return this.vm.$data.faq;
            },

            get hiddenRightAside() {
                return this.vm.$data.hiddenRightAside;
            },

            setPageName(name) {
                this.vm.$data.pageName = name;
            },

            toggleMenu() {
                this.vm.$data.menu = !this.vm.$data.menu;
            },

            toggleHiddenRightAside() {
                this.vm.$data.hiddenRightAside = !this.vm.$data.hiddenRightAside;
            },

            toggleFaq(value = null) {
                const newValue = value !== null ? value : !this.vm.$data.faq;

                this.vm.$data.faq = newValue;
                this.setLocked(newValue);
            },

            toggleUserSettings(value = null) {
                const newValue = value ? value : !this.vm.$data.userSettings;

                this.vm.$data.userSettings = newValue;
                this.setLocked(newValue);
            },

            setLocked(value) {
                this.vm.$data.locked = value;
            },

            updateInfos(infos) {
                this.vm.$data.infos = {
                    ...this.vm.$data.infos,
                    ...infos
                };
            },

            showInfos() {
                this.updateInfos({ isActive: true });
                setTimeout(() => {
                    this.updateInfos({ isVisible: true });
                }, 200);
            },

            hideInfos() {
                this.updateInfos({ isVisible: false });
                setTimeout(() => {
                    this.updateInfos({ isActive: false });
                }, 200);
            },

            // Dashboard methods
            updateDashboard(dashboard) {
                this.vm.$data.dashboard = {
                    ...this.vm.$data.dashboard,
                    ...dashboard
                };
            },

            showDashboard() {
                this.updateDashboard({ isActive: true });
                setTimeout(() => {
                    this.updateDashboard({ isVisible: true });
                }, 200);
            },

            hideDashboard() {
                this.updateDashboard({ isVisible: false });
                setTimeout(() => {
                    this.updateDashboard({ isActive: false });
                }, 200);
            }
        };
    }
};

export default LayoutPlugin;
