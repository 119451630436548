var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "wrapper",
      staticClass: "simple-menu",
      class: [_vm.isVisible ? "is-open" : ""],
      attrs: { title: _vm.title },
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.buttonWrapper
        ? _c(
            "button",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.open.apply(null, arguments)
                },
              },
            },
            [
              _vm.material
                ? _c("i", { staticClass: "material-icons" }, [
                    _vm._v(_vm._s(_vm.icon)),
                  ])
                : _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
            ]
          )
        : [
            _vm.material
              ? _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.open.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.icon))]
                )
              : _c("i", {
                  staticClass: "fa fa-plus-circle",
                  attrs: { "aria-hidden": "true" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.open.apply(null, arguments)
                    },
                  },
                }),
          ],
      _vm._v(" "),
      _c("transition", { attrs: { name: "scale" } }, [
        _vm.isVisible
          ? _c(
              "div",
              {
                ref: "dropdown",
                staticClass: "simple-menu-content",
                on: { click: _vm.close },
              },
              [_vm._t("default")],
              2
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }