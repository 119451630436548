import axios from 'axios';

export default {
    data: function () {
        return {
            cancelSource: null
        };
    },

    methods: {
        cancelSearch : function () {
            if (this.cancelSource) {
                this.cancelSource.cancel('Axios canceled');
            }
        },
        callCancelRequest : function () {
            this.cancelSearch();
            this.cancelSource = axios.CancelToken.source();
        }
    },
    };
